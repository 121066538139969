/** Ionic CSS Variables **/
:root {
  /** primary **/
	--ion-color-primary: #005951;
	--ion-color-primary-rgb: 29,78,216;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #02443e;
	--ion-color-primary-tint: #088278;

  /** secondary **/
  --ion-color-secondary: #275e2a;
  --ion-color-secondary-rgb: 32, 41, 52;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #335c8d;
  --ion-color-secondary-tint: #363e48;

  /** tertiary **/
  --ion-color-tertiary: #7E90A9;
  --ion-color-tertiary-rgb: 126, 144, 169;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #6f7f95;
  --ion-color-tertiary-tint: #8b9bb2;

 

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;


  /** warning **/
  --ion-color-warning: #e8b819;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #d86d6d;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #141A1F;
  --ion-color-dark-rgb: 20, 26, 31;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #12171b;
  --ion-color-dark-tint: #2c3135;

  /** medium **/
	--ion-color-medium: #d3d3db;
	--ion-color-medium-rgb: 161,161,170;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #8e8e96;
	--ion-color-medium-tint: #aaaab3;

  /** light **/
	--ion-color-light: #f7f7f7;
	--ion-color-light-rgb: 250,251,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #dcdde0;
	--ion-color-light-tint: #fbfbff;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

   /** card border **/
   --ion-color-card-border: #d6d6d6;
   --ion-color-card-border-rgb: 214,214,214;
   --ion-color-card-border-contrast: #000000;
   --ion-color-card-border-contrast-rgb: 0,0,0;
   --ion-color-card-border-shade: #bcbcbc;
   --ion-color-card-border-tint: #dadada;
 
   /** form background **/
   --ion-color-form: #ededed;
   --ion-color-form-rgb: 74,74,74;
   --ion-color-form-contrast: #000000;
   --ion-color-form-contrast-rgb: 255,255,255;
   --ion-color-form-shade: #414141;
   --ion-color-form-tint: #5c5c5c;

}
.ion-color-card-border {
	--ion-color-base: var(--ion-color-card-border);
	--ion-color-base-rgb: var(--ion-color-card-border-rgb);
	--ion-color-contrast: var(--ion-color-card-border-contrast);
	--ion-color-contrast-rgb: var(--ion-color-card-border-contrast-rgb);
	--ion-color-shade: var(--ion-color-card-border-shade);
	--ion-color-tint: var(--ion-color-card-border-tint);
}

.ion-color-form {
	--ion-color-base: var(--ion-color-form);
	--ion-color-base-rgb: var(--ion-color-form-rgb);
	--ion-color-contrast: var(--ion-color-form-contrast);
	--ion-color-contrast-rgb: var(--ion-color-form-contrast-rgb);
	--ion-color-shade: var(--ion-color-form-shade);
	--ion-color-tint: var(--ion-color-form-tint);
}

.ios body {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-text-color: #203421;
  --ion-text-color-rgb: 32, 41, 52;
  --ion-item-background: #ffffff;
  --ion-card-background: #ffffff;
}

.ios ion-modal {
  --ion-background-color: #ffffff;
  --ion-toolbar-background: transparent;
  --ion-toolbar-border-color: transparent;
}

body.dark {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

